export const faqList = [
    { id: 1, questions: '1.     I can’t log-on.', ans: 'Please click on ‘forgot password’ and a reminder email will be sent to your registered email address.' },
    { id: 2, questions: '2.     I haven’t received a confirmation email, have I successfully registered?', ans: 'Please check your junk mailbox.  Is the email address you registered with correct? If you have not received an email, please go to login/sign up page and re-enter your email address (user name) and password. ' },
    { id: 3, questions: '3.	    What format can I upload my CV in?', ans: 'You can upload your CV in Word or PDF format. Other documents may have alternative options' },
    { id: 4, questions: '4.     I am a provider and I would like to second my staff to help other hospitals with the Covid-19 situation , can I ?', ans: 'Yes you can, current regulations permit providers to support each other and transfer their current staff to work in other hospitals as needed.' },
    { id: 5, questions: '5.	    I am a health professional but I am not licensed yet, can I volunteer?', ans: 'Yes, you can volunteer, your CV will be reviewed and you will be contacted accordingly. ' },
    { id: 6, questions: '6.	    I am a retired doctor/nurse/allied health professional , can I volunteer or offer my help?', ans: 'Yes you can, please apply to renew your licenses and you will be informed of what are the requirements.' },
    { id: 7, questions: '7.	    I don’t have all the information needed to complete all my profile. Does this matter?', ans: 'As long as you have registered you can be contacted.  You can log in at any time to update your information and upload your CV.  This will enable hospitals to understand your experience and give you the best opportunity of being contacted.' },
    { id: 8, questions: '8.	    I have registered but I have not heard back yet?', ans: 'Please check your junk/spam inbox and keep your profile updated. Recruiters will contact you directly.' },
    { id: 9, questions: '9.	    I am a Physician and I can’t find my Speciality?', ans: 'We have included all of the most common Specialities in this list, we will continue to add more as necessary, you can log in at any time to review and update your information.' },
    { id: 10, questions: '10.	I am a Nurse and I can’t find my Speciality?', ans: "Please choose 'Medical' / 'Surgical', though all the main specialities are listed. You can choose up to 4 areas of expertise. By providing as much information as possible will allow recruiters to understand your experience and skills. You can log in at any time to review and update your information."},
    { id: 11, questions: '11.	I can’t find my Sub-Speciality?', ans: 'We have included all of the most common Sub-specialities in this list, and they are based on the country where you obtained your main Speciality. We will continue to add more as necessary, you can log in at any time to review and update your information.' },
    { id: 12, questions: '12.	I can’t find my Qualification?', ans: 'Your CV will continue to be reviewed if you complete the other fields.  We have included the most common Qualifications in this list, and we will continue to add more as necessary. You can log in at any time to review and update your information. ' },
    { id: 13, questions: '13.	My current location is not displayed?', ans: 'You can leave this field blank if your current location is not specified.' },
    { id: 14, questions: '14.	I have dual nationality, can I select more than one?', ans: 'At the moment you cannot select more than one Nationality, please select your main/current nationality.' },
    { id: 15, questions: '15.	Do I have to be able to speak Arabic?', ans: 'The working language in the UAE is English, though some positions will require knowledge of Arabic but this will be stated in the job advert.' },
    { id: 16, questions: '16.	How can I see more details of the job?', ans: 'Hospitals will review your CV and will contact you directly to discuss their opportunities.' },
    { id: 17, questions: '17.	I have an ABMS, do I still need a local license?', ans: 'All medical professionals are required to obtain a DOH  license to practice.' },
    { id: 18, questions: '18.	What is an Allied Health Professional?', ans: 'Usually clinicians who are not trained physicians/doctors or nurse/midwives will be classed as Allied Health Professionals (AHP), such as Physiotherapists.' },
    { id: 19, questions: '19.	I am visiting the region; can an interview be set up with the hospital?', ans: 'Hospitals will review your CV and contact you directly.' },
    { id: 20, questions: '20.	Which hospitals are advertising their jobs?', ans: 'Hospitals will review your CV and contact you directly and discuss their opportunities.' },
    { id: 21, questions: '21.	What are the hours and shifts?', ans: 'Hospitals will review your CV and contact you directly and discuss their opportunities.' },
    { id: 22, questions: '22.	How long does it take to apply and obtain a medical license?', ans: ['a. Depending on your circumstances, this can take a few weeks but can take approximately 6-9 months if you do not hold an eligibility letter', 'b.	Currently issuing licenses are fast tracked and COVID -19 applications are given priority. '] },
    { id: 23, questions: '23.	What is an eligibility letter?', ans: 'This is issued once you have completed the full DOH licensing process.' },
    { id: 24, questions: '24.	Is accommodation provided?', ans: 'This depends on what job you have been offered.  Usually you will either be provided with accommodation or be offered an accommodation allowance.' },
    { id: 25, questions: '25.	How long are employment contracts?', ans: 'This depends on the hospital but are usually either renewable every 2 years.' },
    { id: 26, questions: '26.	Do I have to pay for your services?', ans: 'There are no recruitment fees payable.' },
    { id: 27, questions: '27.	I have performed a search and no candidates have appeared - why is this?', ans: 'Clinicians with the speciality you have chosen have not registered. Consider using a similar speciality.' },
    { id: 28, questions: '28.	What Browser do I need?', ans: 'You can access the system using a variety of Browsers, including Chrome, FireFox and Edge. The system is also accessible on your mobile phone or other smart device.' },
    { id: 29, questions: '29.	Are there any system specific requirements, such as Java, Flash etc?', ans: 'No, this is a web-based application.' },
]