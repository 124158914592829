<template>
  <div>
    <div class="candidate-profile py-4 mb-5">
      <div class="pb-5 mb-5">
        <button class="ml-3 back_btn btn rounded-1 btn-primary btn-md" @click="goingback">Go Back</button>
        <div class="container-fluid mt-5">
          <h3 class="pb-3 px-1 mb-0">Frequently asked questions</h3>
          <CListGroup>
            <CListGroupItem v-for="faq in getfaq" :key="faq.id">
              <div @click="showToggle(faq.id)" style="cursor:pointer" class="d-flex justify-content-between">
                <p>{{faq.questions}}</p>
                <i v-if="!isToggled(faq.id)" class="fas fa-plus-circle"></i>
                <i v-if="isToggled(faq.id)" class="fas fa-minus-circle"></i>
              </div>
              <div>
                <CCollapse :show="isToggled(faq.id)">
                  <CCard
                    body-wrapper
                    class="d-flex border-bottom-0 border-left-0 border-right-0 rounded-0"
                  >
                    <CCardBody class="d-flex">
                      <i style="color:#206d75" class="fas fa-hand-point-right mt-1"></i>
                      <div class="d-flex flex-column">
                      <p v-if="!Array.isArray(faq.ans)" class="ml-2">{{faq.ans}}</p>
                      <div v-if="Array.isArray(faq.ans)" v-for="(answer) in faq && faq.ans" class="ml-2"><p>{{answer}}</p></div>
                      </div>
                    </CCardBody>
                  </CCard>
                </CCollapse>
              </div>
            </CListGroupItem>
          </CListGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { faqList } from "@/schema/faq";

export default {
  name: "Faq",
  data() {
    return {
      selectedFaqId: undefined,
      collapse: false,
      innerCollapse: false
    };
  },
  computed: {
    getfaq() {
      return faqList;
    }
  },
  methods: {
    showToggle(id) {
      if (this.selectedFaqId && this.selectedFaqId === id) {
        this.selectedFaqId = undefined;
      } else {
        this.selectedFaqId = id;
      }
    },
    isToggled(id) {
      return this.selectedFaqId === id;
    },
    goingback() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped>
@media only screen and (max-width: 480px) {
  .back_btn {
    margin-top: 10px;
  }
}
</style>